.wrapper {
    position: relative;
}

.tableWrapper {
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    background-color: rgba(255, 255, 255, 0);
    overflow: auto;
    max-height: 250px;
    min-height: 250px;
}

.table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.tableHead {
    background: #e9f4ff;
}

.tableRowHeader {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableCellHeaderInputSearch {
    height: 36px;
    border-radius: 6px;
}

.tableCell {
    display: table-cell;
    padding: 10px;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    color: #212121;
}

.tableCellHeader {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5rem;
    background: #e9f4ff;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.tableCellCenter {
    text-align: center;
}

.tableBody {
    display: table-row-group;
}

.tableRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableCellFirstChildRoot {
    padding: 8px 0 0 40px;
    border-left: 1px solid "#1e90ff";
}

.cloudIconBtn {
    padding: 0 3px !important;
    border: 0 !important;
}

.cloudIcon {
    width: 24px;
    height: 24px;
}

.hiddenRow {
    display: none;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.searchFilterWrap {
    position: absolute;
    top: 70px;
    left: 50px;
}

.radioGroup {
    display: flex !important;
    flex-direction: column;
    margin-bottom: 30px !important;
}

.radioItem {
    margin-bottom: 10px !important;
    font-size: 16px !important;
    color: #212121 !important;
}

.radioItemRoute {
    margin-left: 25px !important;
}
.emptyRowData {
    text-align: center;
    margin: 30px;
}
.OKBtn {
    position: absolute !important;
    right: 0 !important;
    color: #1e90ff !important;
    border: 2px solid !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    font-weight: bold !important;
}
.inputError {
    border: red 1px solid !important;
}
