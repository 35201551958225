.cardItem {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    min-width: 300px;
    height: 100px;
    border-radius: 10px;
    margin-right: 27px;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
}

.wrapper {
    padding: 20px 0;
    display: grid;
    grid-gap: 20px 0;
    grid-template-columns: 20px 1fr 20px;
    align-content: start;
    width: 100%;
    overflow-y: auto;
}

.container {
    grid-column: 1 / -1;
    overflow-y: hidden;
    margin-bottom: calc(-0.1 * 20px);
    padding-bottom: 20px;
}

.listItem {
    display: grid;
    grid-gap: calc(20px / 2);
    grid-template-columns: 10px repeat(6, calc(45% - 20px * 2)) 10px;
    grid-template-rows: minmax(150px, 1fr);
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    padding-bottom: calc(0.75 * 20px);
    margin-bottom: calc(-0.5 * 20px);
    list-style: none;
    padding: 0;
}
/*.listItem:after looks it's working fine without after*/
.listItem:before {
    content: "";
}

.item {
    scroll-snap-align: center;
    padding: calc(20px / 2 * 1.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
    margin-right: 20px;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    transition: background 0.5s;
    position: relative;
}

.definedUser__title {
    color: #939393;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
}

.activeItem {
    background: #e9f4ff;
}

.newItemWrap {
    width: 100%;
    display: flex;
}

.newItemFormWrap {
    flex: 1;
}

.dropdownItem {
    width: 100%;
    margin-bottom: 20px;
}

.inputItemWrap {
    display: flex;
    align-items: center;
}

.btnItem {
    margin-left: 10px;
    height: 40px !important;
    color: #1e90ff !important;
    border: 1px solid !important;
}

.closeIconWrap {
    width: 20px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
}

.closeBtn {
    border: none !important;
    width: 100%;
}

.closeIcon {
    height: 12px;
    width: 12px;
}

.itemBodyWrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.itemBodyIcon {
    width: 74px;
    height: 74px;
}

.itemBodyContentWrap {
    margin-left: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.itemBodyInfoNo {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #a5d3ff;
    border-radius: 6px;
    padding: 5px 10px;
}

.itemBodyInfoNo__text {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
    color: #212121;
    text-transform: uppercase;
}

.itemBodyInfoNo__no {
    color: #1e90ff;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

.itemBodyInfoNo__no_1 {
    color: #1e90ff;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
}

.itemBodyContentTitleWrap {
    display: flex;
    align-items: center;
}

.itemBodyContentLabel {
    color: #1e90ff;
}

.itemBodyTitle {
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
    text-transform: lowercase;
}

.itemBodyTitle:first-letter {
    text-transform: uppercase; /* Make the first letters uppercase      */
}

.itemBodyNo {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #a5d3ff;
    margin-bottom: 0;
}

.itemBodyCloseBtn {
    position: absolute !important;
    top: 0;
    right: 0;
    border: none !important;
    padding: 0 !important;
    background: transparent !important;
}

.itemBodyCloseIcon {
    width: 20px;
    height: 20px;
}

.itemBodyEditBtn {
    position: absolute !important;
    bottom: 0;
    right: 0;
    border: none !important;
    padding: 0 !important;
    background: transparent !important;
}

.itemBodyEditIcon {
    width: 17px;
    height: 17px;
}

.error {
    position: absolute;
    bottom: 0;
    left: 20px;
}
