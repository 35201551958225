.baseInput {
    height: 40px;
}

.baseFileInputBtn {
    color: #1e90ff !important;
    border: 1px solid !important;
    background: #fff !important;
    width: 100% !important;
    height: 39px !important;
    font-weight: bold !important;
    border-radius: 4px !important;
}

.fileNameWrap {
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 39px;
    display: flex;
    padding: 10px 0 10px 14px;
    align-items: center;
    border-radius: 6px;
}

.fileName {
    flex: 1;
}

.removeIconBtn {
    border: none !important;
}

.removeIcon {
    width: 10px;
    height: 10px;
}

/* Base Search Input */
.searchInputWrap {
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    width: 600px !important;
}
