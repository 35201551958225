.wrapper {
    margin: 0;
    overflow: auto;
    max-height: 270px;
}

.table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.tableHead {
    background: #e9f4ff;
}

.tableRowHeader {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableCell {
    display: table-cell;
    padding: 12px;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    color: #212121;
}

.tableCellHeader {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5rem;
    background: #e9f4ff;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.tableBody {
    display: table-row-group;
}

.tableRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.initial_padding span {
    padding-left: 20px;
}

.tableRowActive {
    background-color: #a5d3ff;
}

.expandIconCell {
    max-width: 20px;
    padding: 0 5px;
}

.expandIconBtn {
    border: none !important;
    background: transparent !important;
}

.expandIcon {
    fill: currentColor;
    width: 12px;
    height: 12px;
    display: inline-block;
    font-size: 14px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.tableCellNameStatus {
    background-color: #ff71b9;
    color: #fff;
    margin-right: 10px;
    font-size: 10px;
    font-weight: bold;
    padding: 5px 20px;
    border-radius: 4px;
}

.tableCellFirstChildRoot {
    padding: 8px 0 0 40px;
    border-left: 1px solid "#1e90ff";
}

.hiddenRow {
    display: none;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.iconBtn {
    border: none !important;
    background: transparent !important;
}

.menuAcitonWrap {
    padding: 17px;
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionTitle {
    margin-left: 11px;
}
.openTask {
    margin-left: 10px;
}

.rejectIconWrap {
    margin-left: 3px;
}

.rejectIcon {
    width: 20px;
    height: 20px;
}

.highlight_row {
    background: #a123;
}
.sort_icon {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    vertical-align: baseline;
}
.sort_icon path {
    fill: #1e90ff;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
