.wrapper {
    padding: 50px 20px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 20px;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.titleWrap {
    flex: 1;
    display: flex;
    align-items: center;
}

.headerTitle {
    margin-bottom: 0;
    margin-left: 20px;
}

.backIconBtn {
    border: none !important;
    height: 100% !important;
    padding: 0 !important;
}

.backIcon {
    width: 40px;
    height: 40px;
}

.tabIcon {
    width: 20px;
}

.tabTitle {
    margin-left: 10px;
    font-size: 16px;
    color: #212121;
}

.tabsWrapper {
    overflow: auto !important;
    max-height: calc(100vh - 200px);
}
