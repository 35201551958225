.wrapper {
    margin: 20px 0;
}

.table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.tableHead {
    background: #e9f4ff;
}

.tableRowHeader {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableCell {
    display: table-cell;
    padding: 12px;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    color: #212121;
}

.tableCellHeader {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5rem;
}

.tableCellCenter {
    text-align: center;
}

.tableBody {
    display: table-row-group;
}

.tableRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.expandIconCell {
    max-width: 20px;
    padding: 0 5px;
}

.expandIconBtn {
    border: none !important;
}

.expandIcon {
    fill: currentColor;
    width: 12px;
    height: 12px;
    display: inline-block;
    font-size: 14px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.tableCellFirstChildRoot {
    padding: 8px 0 0 40px;
    border-left: 1px solid "#1e90ff";
}

.statusWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    padding: 5px 8px;
    border-radius: 4px;
}

.overdueStatus {
    background-color: var(--warning-400-base);
}

.openStatus {
    background-color: var(--primary-400-base);
}

.completedStatus {
    background-color: #18a957;
    text-align: center;
}

.progressText {
    color: #18a957;
    text-transform: uppercase;
    margin: 0;
    font-size: 14px;
}

.hiddenRow {
    display: none;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.iconBtn {
    border: none !important;
}

.menuAcitonWrap {
    padding: 17px;
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionPackageIcon {
    width: 20px;
    height: 20px;
}

.menuActionTitle {
    margin-left: 11px;
}

.cursor {
    cursor: pointer;
}

.emptyRowData {
    text-align: center;
    margin: 30px;
}

.rowLoading {
    text-align: center;
    margin: 30px;
}
