.baseBtn {
    color: #fff !important;
    background-color: #ff349b !important;
    border-radius: 4px !important;
    padding: 7px 17px !important;
    height: auto !important;
    display: flex !important;
    align-items: center;
    font-weight: bold !important;
}

.baseBtn:hover {
    background-color: #ff7ec0 !important;
    color: #fff;
    border-color: inherit;
}

.baseAddCircleBtn {
    background-color: #ff349b !important;
    height: 50px !important;
    width: 50px !important;
}

.baseAddCircleBtn:hover,
.baseAddCircleBtn:active {
    background-color: #ff7ec0 !important;
}

.iconPlus {
    height: 30px;
}
