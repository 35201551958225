.itemFilterWrap {
    margin-right: 20px;
}

.itemFilterBodyWrap {
    margin-right: 20px;
    margin-bottom: 10px;
}

.itemLabel {
    margin-bottom: 7px;
}

.filterBtnWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.filterBtn {
    border: none !important;
    color: #1e90ff !important;
}

.filterBtnText {
    margin-right: 5px;
}

.filterBtnIcon {
    fill: currentColor;
    width: 12px;
    height: 12px;
    display: inline-block;
    font-size: 14px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.filterOptionsBody {
    background-color: #e9f4ff;
    padding: 20px 40px;
    margin-top: 20px;
    position: relative;
}

.filterDateBodyWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.filterBodyBtnWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.filterBodyBtn {
    border: 1px solid #1e90ff !important;
    background-color: transparent !important;
    color: #1e90ff !important;
    font-weight: bold !important;
}

.filterBodyBtn:hover {
    background-color: transparent;
}

.itemStaff {
    background-color: #e9f4ff;
    padding: 20px 40px 60px;
    margin-top: 20px;
}

.itemStaffFilterBodyWrap {
    display: flex;
    align-items: center;
}

.itemStaffDropDownWrap {
    flex: 1;
}

.staffFilterBodyBtn {
    height: 40px !important;
    margin-left: 40px;
}

.filterSeparator {
    width: 100%;
    color: #fff;
    border-color: #fff;
    border-width: 2px;
}
