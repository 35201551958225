.titleWrap {
    display: flex;
    align-items: center;
}

.backIconBtn {
    border: none !important;
    height: 100% !important;
    padding: 0 !important;
}

.backIcon {
    width: 40px;
    height: 40px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: 20px;
}

.tabWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 20px 0 5px;
    border-bottom: 2px solid #1e90ff;
}

.tabIcon {
    width: 20px;
}

.tabTitle {
    margin-left: 40px;
    font-size: 16px;
    color: #212121;
}

.rowItem {
    /* margin-bottom: 15px; */
}

.itemBodyWrap {
    margin-right: 20px;
    margin-bottom: 25px;
}

.bodyTitle {
    margin-bottom: 3px;
    color: #212121;
    font-size: 16px;
    height: 28px;
}

.sendBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}
