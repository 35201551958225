.wrap {
    padding: 0 40px 30px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 15px;
}

.description {
    font-size: 16px;
    color: #212121;
    margin-bottom: 82px;
}

.actionWrap {
    display: flex;
    align-items: center;
}

.cancelBtn {
    height: 40px;
    color: #1e90ff;
    border: 1px solid;
}

.accept {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.acceptBtn {
    height: 40px;
}
