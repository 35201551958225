.colFilter {
    margin-right: 12px;
}

.filterBtnWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.filterBtn {
    border: none !important;
    color: #1e90ff !important;
}

.applyBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}

.createBtn {
    margin-right: 20px;
}

.createTaskWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.iconPlus {
    height: 24px;
}

.paginationWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}
