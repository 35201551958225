.wrapper {
    margin: 20px 0;
    overflow-x: scroll;
}

.table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.tableHorizontalScroll {
    width: unset;
    display: block;
    table-layout: fixed;
    margin: 0 auto;
}

.tableHead {
    background: #e9f4ff;
}

.bodyTable {
    max-height: 100px;
    overflow: auto;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.tableRowHeader {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableCell {
    display: table-cell;
    padding: 12px;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    color: #212121;
}

.tableCellHeader {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5rem;
    position: relative;
}

.tableCellCenter {
    text-align: center;
}

.tableBody {
    display: table-row-group;
}

.tableRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.expandIconCell {
    max-width: 20px;
    padding: 0 5px;
}

.expandIconBtn {
    border: none !important;
}

.expandIcon {
    fill: currentColor;
    width: 12px;
    height: 12px;
    display: inline-block;
    font-size: 14px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.tableCellFirstChildRoot {
    padding: 8px 0 0 40px;
    border-left: 1px solid "#1e90ff";
}

.status {
    display: block;
    width: 18px;
    height: 18px;
    background: #df1642;
    border-radius: 50%;
}

.statusWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    padding: 5px 8px;
    border-radius: 4px;
}

.overdueStatus {
    background-color: var(--warning-400-base);
}

.openStatus {
    background-color: var(--primary-400-base);
}

.completedStatus {
    background-color: #18a957;
    text-align: center;
}

.hiddenRow {
    display: none;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.iconBtn {
    border: none !important;
}

.menuAcitonWrap {
    padding: 17px;
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionPackageIcon {
    width: 20px;
    height: 20px;
}

.menuActionTitle {
    margin-left: 11px;
}

.cursor {
    cursor: pointer;
}

.emptyRowData {
    text-align: center;
    margin: 30px;
}

.rowLoading {
    text-align: center;
    margin: 30px;
}

.plusIconBtn {
    background: transparent !important;
    border: none !important;
}

.iconPlus {
    height: 24px;
}

.colsPicker {
    position: absolute;
    background: #fff;
    z-index: 99;
    width: 230px;
    right: 50px;
    top: 0;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.closeColsPickerIcon {
    position: absolute !important;
    top: 2px;
    right: 8px;
    border: 0 !important;
    width: 20px !important;
    height: 20px !important;
}

.closeIcon {
    width: 19px;
    height: 19px;
}

.colsPickerTitle {
    font-size: 16px;
    font-weight: 600;
    color: #212121;
    margin-bottom: 17px;
}

.colsPickerDesc {
    font-size: 16px;
    color: #212121;
    margin-bottom: 10px;
}

.colsPickerDescAvailable {
    font-size: 16px;
    color: #212121;
    margin-top: 13px;
    margin-bottom: 10px;
}

.colsPickerBody {
    max-height: 400px;
    overflow: auto;
}

.availableCheckboxes {
    display: flex !important;
    flex-direction: column;
}

.OKBtnCheckbox {
    border: 1px solid #1e90ff !important;
    color: #1e90ff;
}
