.title {
    font-size: 20px;
    font-weight: bold;
}

.inputReadOnly {
    caret-color: transparent;
}

.typeWrap {
    margin-bottom: 30px;
}

.typeTitle {
    margin-right: 20px;
    font-size: 16px;
}

.radioBtn {
    margin-right: 20px;
    font-size: 16px;
}

.rowItem {
    /* margin-bottom: 15px; */
}

.bodyTitle {
    margin-bottom: 3px;
    color: #212121;
    font-size: 16px;
    height: 28px;
}

.itemBodyWrap {
    margin-right: 20px;
    margin-bottom: 14px;
}

.divider {
    background-color: #1e90ff;
    height: 2px;
    margin-bottom: 21px !important;
}

.footerWrap {
    display: flex;
}

.footerBtnWrap {
    margin-top: 50px;
    margin-bottom: 38px;
    text-align: right;
}

.saveBtnWrap {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.cancelBtn {
    color: #1e90ff;
    border: 1px solid;
}

.checkboxItem {
    margin-bottom: 3px;
}
