@import "variables.css";
@import "~antd/dist/antd.css";

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.body {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

body,
html {
    margin: 0;
    font-family: "Raleway", sans-serif;
    line-height: 19px;
    font-size: 16px;
    color: var(--black-base);
    font-weight: normal;
}
* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

.required-star {
    color: rgb(255, 47, 47);
    font-size: 18px;
}

/* Custom ant design */
.ant-tabs.fullwidth .ant-tabs-nav,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
}

.ant-tabs.fullwidth .ant-tabs-nav > div {
    display: flex;
}

.ant-tabs.fullwidth .ant-tabs-nav .ant-tabs-tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-modal-close {
    top: 20px;
    right: 20px;
}

.ant-modal-close-x {
    width: 25px;
    height: 25px;
    line-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
}

.ant-pagination-options {
    display: none;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    height: 24px;
    line-height: 24px;
    min-width: 24px;
    background-color: #e6e6e6;
    margin-right: 4px;
}

.ant-pagination-item-active {
    background-color: #1e90ff;
    color: #fff;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
}

.ant-picker {
    width: 100%;
    height: 40px;
}

.ant-input-search .ant-input {
    height: 48px;
}

.ant-input,
.ant-picker {
    height: 44px;
    font-size: 16px;
}

.ant-input.error,
.ant-picker.error {
    border: 1px solid #ff6347;
}

.ant-input-search-button {
    background: #ff349b !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
}

.ant-input-search-button:hover,
.ant-input-search-button:active {
    background: #ff7ec0 !important;
}

.ant-input-search .anticon-search svg {
    width: 18px;
    height: 18px;
}

.ant-btn > .ant-btn-loading-icon {
    display: flex;
}

.ant-modal {
    top: 50px;
}

.base-popup .ant-modal-body {
    padding: 0;
}

.ant-select-selector {
    min-height: 40px;
}

.ant-select-multiple .ant-select-selection-item {
    background-color: #1e90ff;
    border-radius: 4px;
    color: #fff;
}

.ant-select-multiple .ant-select-selection-item-content {
    margin-right: 6px;
}

.ant-select-multiple .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
    fill: #fff;
}
.ant-tabs.ant-tabs-top.fullwidth {
    overflow: visible;
}
.ant-upload.ant-upload-select {
    width: 100%;
}

.border-green .ant-checkbox {
    border: 1px solid #1e90ff;
    border-radius: 4px;
}

.circle-checkbox {
    position: relative;
    width: 100%;
}

.circle-checkbox .ant-checkbox-inner {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    background: transparent;
    border-color: #fff !important;
    z-index: 2;
}

.circle-checkbox .ant-checkbox {
    position: absolute;
    right: 10px;
    top: calc(50% - 6px);
    z-index: 1;
}

.col-picker .ant-checkbox-group-item {
    margin-bottom: 15px;
}

.pointer {
    cursor: pointer;
}

.pdf-document-wrap canvas {
    width: 100% !important;
    height: auto !important;
}
