.itemWrap {
    padding: 40px 60px;
}

.titleWrap {
    display: flex;
    align-items: center;
}

.headerTitle {
    margin-bottom: 0;
    margin-left: 20px;
}

.backIconBtn {
    border: none !important;
    height: 100% !important;
    padding: 0 !important;
}

.backIcon {
    width: 40px;
    height: 40px;
}

.tabIcon {
    width: 20px;
}

.tabTitle {
    margin-left: 10px;
    font-size: 16px;
    color: #212121;
}

.tabsWrapper {
    overflow: auto;
}

.bodyWrap {
    padding: 20px;
    background: #e9f4ff;
    margin-bottom: 40px;
}

.title {
    color: #212121;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0;
}

.description {
    color: #212121;
    font-size: 16px;
    margin-bottom: 20px;
}

.bodyInfoWrap {
    display: flex;
    margin-bottom: 40px;
}

.bodyInfoItem {
    margin-right: 30px;
}

.bodyInfoItem__title {
    font-size: 14px;
    margin-bottom: 0;
}

.bodyInfoItem__value {
    color: #1e90ff;
}

.actionWrap {
    display: flex;
}

.leftActionBtn {
    color: #1e90ff !important;
    border: 1px solid !important;
    padding: 5px 10px !important;
    height: 40px !important;
    width: 110px !important;
    background: transparent !important;
    margin-right: 20px;
}

.rightActionWrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.formBodyWrap {
    display: flex;
}

.dropzoneWrap {
    flex: 1;
}

.dropzoneItem {
    margin-right: 30px;
    padding: 40px;
    outline: none;
    border: 1px dashed #cfcfcf;
}

.dropzone__text {
    font-size: 16px;
    font-weight: bold;
    color: #212121;
}

.dropzone__btn {
    background: #1e90ff !important;
    border-radius: 4px !important;
    color: #fff !important;
}

.listFiles {
    flex: 1;
    margin-right: 30px;
}

.fileItem {
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    margin-bottom: 30px;
}

.fileItem__img {
    height: 80px;
    width: 80px;
    border-radius: 10px 0 0 10px;
}

.fileItem__content {
    flex: 1;
    margin-left: 40px;
    max-width: 100%;
    word-break: break-all;
}

.fileItem__contentText {
    margin-bottom: 0;
}

.fileItem__contentTextBold {
    font-weight: bold;
}

.fileItem__removeIconWrap {
    margin-right: 16px;
}

.fileItem_removeIconBtn {
    cursor: pointer;
    padding: 0 !important;
    border: 0 !important;
    height: auto !important;
}

.fileItem_removeIcon {
    width: 24px;
    height: 24px;
}
