.divider {
    background-color: #1e90ff;
    height: 2px;
    margin-top: 35px;
    margin-bottom: 21px;
}

.rowStatusWrap {
    margin-bottom: 15px;
}

.statusItem {
    flex: 1;
}

.statusBtn {
    height: 32px;
    width: 100%;
    border-radius: 4px;
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: bold !important;
}

.reject {
    background-color: #df1642 !important;
}

.resubmit {
    background-color: #ff8a38 !important;
}

.approve {
    background-color: #18a957 !important;
}

.remind {
    background-color: #1e90ff !important;
}

.inputWrap {
}

.actionWrap {
    display: flex;
}

.applyBtn {
    height: 40px;
    color: #1e90ff;
    border: 1px solid;
    margin-left: 17px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
}

.doneBtnWrap {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.actionWrap1 {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.cancelBtn {
    height: 40px !important;
    color: #1e90ff !important;
    border: 1px solid !important;
}

.accept {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.acceptBtn {
    height: 40px !important;
}

.title {
    margin-bottom: 0;
}

.OKBtn {
    margin-top: 30px;
    height: 40px !important;
    float: right;
}

.pdfViewWrap {
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttonWrap {
    margin-left: 50px;
}

.preIcon {
    border: 0 !important;
    margin-right: 30px;
}

.nextIcon {
    border: 0 !important;
}
