.wrapper {
    margin: 20px;
}

.table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.tableHead {
    background: #e9f4ff;
}

.tableRowHeader {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableCell {
    display: table-cell;
    padding: 12px;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    color: #212121;
}

.tableCellHeader {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5rem;
}

.tableCellCenter {
    text-align: center;
}

.tableBody {
    display: table-row-group;
}

.tableRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableRowChild {
    border-left: 1px solid #1e90ff;
}

.expandIconCell {
    max-width: 20px;
    padding: 0 5px;
}

.expandIconBtn {
    border: none !important;
}

.expandIcon {
    fill: currentColor;
    width: 12px;
    height: 12px;
    display: inline-block;
    font-size: 14px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.tableCellFirstChildRoot {
    padding: 8px 0 0 40px;
    border-left: 1px solid "#1e90ff";
}

.rowItemStatus {
    padding-left: 8px;
}

.hiddenRow {
    display: none;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.iconBtn {
    border: none !important;
}

.menuAcitonWrap {
    padding: 17px;
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionPackageIcon {
    width: 20px;
    height: 20px;
}

.menuActionTitle {
    margin-left: 11px;
}
