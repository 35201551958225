.wrapper {
    margin: 50px 20px 20px 20px;
}

.tasksHeaderWrap {
    display: flex;
    margin-bottom: 66px;
}

.taskHeaderTitle {
    flex: 1;
    font-size: 37px;
    font-weight: bold;
    margin-bottom: 0;
}

.tabIcon {
    width: 20px;
}

.tabTitle {
    margin-left: 10px;
    font-size: 16px;
    color: #212121;
}

.tasksCardContainer {
    display: flex;
    margin-bottom: 75px;
}

.tasksCardItemWrap {
    flex: 1;
    margin-right: 20px;
}

.tasksCardItemWrap:last-child {
    margin-right: 0;
}

.taskCardWrap {
    border-radius: 10px;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    background-color: rgba(255, 255, 255, 0);
    border-radius: 10px;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 250px;
}

.taskCardInner {
    padding: 20px;
    margin-top: 20px;
}

.taskCardNo {
    background-color: #1e90ff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 20px;
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.taskCardStatus {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px;
}

.taskCardDesc {
    font-size: 11px;
    margin-bottom: 20px;
    flex: 1;
    text-align: center;
    margin: 0px 30px;
}

.taskCardBtn {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
}

.scanIndividualBtn {
    float: right;
    margin-bottom: 20px;
}
