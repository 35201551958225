.title {
    font-size: 20px;
    font-weight: bold;
}

.tabIcon {
    width: 20px;
}

.tabTitle {
    margin-left: 10px;
    font-size: 16px;
    color: #212121;
}

.tabsWrapper {
    overflow: auto !important;
    max-height: calc(100vh - 200px);
}

.typeWrap {
    margin-bottom: 30px;
}

.typeTitle {
    margin-right: 20px;
    font-size: 16px;
}

.radioBtn {
    margin-right: 20px;
    font-size: 16px;
}

.bodyTitle {
    margin-bottom: 3px;
    color: #212121;
    font-size: 16px;
    height: 28px;
}

.itemBodyWrap {
    margin-right: 20px;
    margin-bottom: 25px;
    position: relative;
}

.error {
    position: absolute;
    bottom: -23px;
    margin: 0;
}

.divider {
    background-color: #1e90ff;
    height: 2px;
    margin-top: 35px;
    margin-bottom: 21px;
}

.footerWrap {
    display: flex;
}

.footerBtnWrap {
    margin-top: 50px;
    margin-bottom: 38px;
    text-align: right;
}

.saveBtnWrap {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.cancelBtn {
    color: #1e90ff;
    border: 1px solid;
}

.checkboxItem {
    margin-bottom: 3px;
}

.chooseDocsWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.handIcon {
    width: 74px;
    height: 74px;
}

.chooseDocsDescription {
    margin-top: 17px;
    font-size: 16px;
    color: #212121;
}

.checkBoxAutoApprove {
    border: 2px solid var(--blue-500);
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    display: inline-block;
    margin-top: 30px;
}
