.itemFilterWrap {
    margin-right: 20px;
}

.itemFilterBodyWrap {
    margin-right: 20px;
    margin-bottom: 10px;
}

.itemLabel {
    margin-bottom: 7px;
}

.filterBtnWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.filterBtn {
    border: none !important;
    color: #1e90ff !important;
}

.filterBtnText {
    margin-right: 5px;
}

.filterBtnIcon {
    fill: currentColor;
    width: 12px;
    height: 12px;
    display: inline-block;
    font-size: 14px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.filterOptionsBody {
    background-color: #e9f4ff;
    padding: 20px 40px;
    margin-top: 20px;
    position: relative;
}

.filterOptionsBody::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    left: 77%;
    transform: translateX(-50%) rotate(45deg);
    background-color: #e9f4ff;
}

.filterDateBodyWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.filterBodyBtnWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.filterBodyBtn {
    border: 1px solid #1e90ff !important;
    background-color: transparent !important;
    color: #1e90ff !important;
    font-weight: bold !important;
}

.filterBodyBtn:hover {
    background-color: transparent;
}
